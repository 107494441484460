
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs } from 'vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import Q from 'q';
import { Coupon, Currency, CustomerType, PriceTypeResource, SubCoupon } from '@/models/Provider';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import RadioInput from '@/components/RadioInput.vue';
import { AwaitTaskResponse, DateRange, IdName, IdText } from '@/models/Interfaces';
import { generalStore } from '@/store';
import { CouponLimitationType, CouponType, SubCouponStatus, ToOptions, CouponDiscountType, CouponWhoFinances } from '@/models/Enums';
import useOrder from '@/modules/useOrder';
import { Club } from '@/models/Provider';
import { SubCouponsFilter } from '@/models/Coupons';
import { ApiResult } from '@/services/BaseApi';
import _ from 'lodash';

export default defineComponent({
    components: { Multiselect, Calendar, RadioInput },
    name: 'CouponDetails',
    props: {
        couponId: {
            type: String
        }
    },
    async setup(props) {
        const { convertDate } = useOrder();
        const { t } = useI18n();
        const limitationTypes = ToOptions(CouponLimitationType, 'coupon-limitation-type.');
        const couponDateRange = reactive(new DateRange());
        const isEdit = !!props.couponId;
        const discountCurrencyId = ref('');
        const discountIsAmount = computed(() => discountCurrencyId.value != '');
        const { couponId } = toRefs(props);

        const coupon = ref<Coupon>(new Coupon());
        const showCodes = ref(false);
        const filter = ref(new SubCouponsFilter());
        const subCoupons = ref<SubCoupon[]>([]);
        const hasNext = ref(false);
        const subCouponsUsed = ref(0);
        const subCouponsReserved = ref(0);
        const subCouponsLeft = ref(0);

        if (coupon.value.club == null) {
            coupon.value.club = new Club();
        }

        const couponPassed = computed(() => coupon.value.passed);
        const subCouponTableContainer = ref<HTMLElement | null>(null);

        const businessClientTypesTranslations = ref<IdText[]>([]);

        const isClubDisabled = computed(() => coupon.value.businessClientTypeId !== null && coupon.value.businessClientTypeId !== '' );

        // Mapping object for sub coupon status
        const subCouponStatusLabels = {
            [SubCouponStatus.Created]: t('coupon.sub-coupon.status-option.free'),
            [SubCouponStatus.Reserved]: t('coupon.sub-coupon.status-option.reserved'),
            [SubCouponStatus.Used]: t('coupon.sub-coupon.status-option.used')
        };

        const GetSubCouponsApiCall = async () => {
            swal.fire({
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    swal.showLoading();
                },
                showConfirmButton: false
            });

            const subCouponsRes = await api.getSubCoupons(couponId.value!, filter.value);

            if (subCouponsRes.error || !subCouponsRes.data) {
                swal.fire({
                    icon: 'error',
                    text: subCouponsRes.errorMessage
                });
                return;
            }

            hasNext.value = subCouponsRes.data?.hasNext || false;

            if (subCouponsRes.data && subCoupons.value && subCoupons.value.length > 0) {
                subCoupons.value = [...subCoupons.value, ...subCouponsRes.data.rows];
            } else {
                subCoupons.value = subCouponsRes.data?.rows || [];
            }

            subCouponsUsed.value = subCouponsRes.data.subCouponsUsed;
            subCouponsReserved.value = subCouponsRes.data.subCouponsReserved;
            subCouponsLeft.value =
                subCouponsRes.data.totalFiltered - subCouponsRes.data.subCouponsUsed - subCouponsRes.data.subCouponsReserved;

            filter.value.pageIndex++;
            swal.close();
        };

        function ListenerGetSubCoupons() {
            if (hasNext.value) {
                GetSubCouponsApiCall();
            }
        }

        // Utility function to convert enum to array of objects
        const enumSubCouponStatusToArray = (enumObj: typeof SubCouponStatus): { id: number; name: string }[] => {
            return Object.values(enumObj)
                .filter((value): value is SubCouponStatus => typeof value === 'number') // Type guard to assert value is SubCouponStatus
                .map(value => ({
                    id: value,
                    name: subCouponStatusLabels[value]
                }));
        };

        const handleScroll = () => {
            if (subCouponTableContainer.value) {
                const container = subCouponTableContainer.value;
                if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                    if (hasNext.value) {
                        ListenerGetSubCoupons();
                    }
                }
            }
        };

        onMounted(async () => {
            const data = await generalStore.dispatch('getBusinessClientTypes');
            businessClientTypesTranslations.value = data.map((x: any) => {
                return {
                    id: x.id,
                    text: t('businessClientTypes.' + x.text.replace(' ', ''))
                };
            });
            
            if (subCouponTableContainer.value) {
                subCouponTableContainer.value.addEventListener('scroll', handleScroll);
            }
        });

        onBeforeUnmount(() => {
            if (subCouponTableContainer.value) {
                subCouponTableContainer.value.removeEventListener('scroll', handleScroll);
            }
        });

        if (couponId.value) {
            swal.showLoading();
            const response = await api.getCoupon(couponId.value);

            if (response.error || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                swal.close();
                return;
            }

            if (response.data.couponType === CouponType.NextPurchase) await GetSubCouponsApiCall();

            swal.close();

            coupon.value = response.data;
            if (coupon.value.club == null) {
                coupon.value.club = new Club();
            }
            if (coupon.value.validDateFrom) {
                couponDateRange.fromDate = new Date(coupon.value.validDateFrom);
            }
            if (coupon.value.validDateTo) {
                couponDateRange.toDate = new Date(coupon.value.validDateTo);
            }

            if (coupon.value.discountAmount) {
                discountCurrencyId.value = coupon.value.currencyId;
            }
        }

        async function filterSubCoupons() {
            filter.value.pageIndex = 0;
            subCoupons.value = [];
            generalStore.commit('saveSubCouponFilter', filter.value);
            await GetSubCouponsApiCall();
        }

        async function showAll() {
            generalStore.commit('saveSubCouponFilter', new SubCouponsFilter());
            filter.value = new SubCouponsFilter();
            subCoupons.value = [];
            await GetSubCouponsApiCall();
        }

        async function awaitTask(action: () => Promise<ApiResult<AwaitTaskResponse>>, onSuccess: (url: string) => void, waitTime = 3000) {
            swal.fire(t('swalAction.processing'));
            swal.showLoading();
            await Q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        const exportSubCoupons = async () => {
            swal.showLoading();
            const filterData: SubCouponsFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResult = await api.exportSubCoupons(couponId.value!, filterData);

            if (apiResult.data?.error || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error || 'No data'
                });
                return;
            }

            const taskId = apiResult.data.taskId;

            awaitTask(
                () => api.waitExportTask(taskId),
                url => {
                    swal.fire({
                        icon: 'success',
                        title: t('alert.exportReady.orders'),
                        html: `
                        <div class="text-center my-4">
                            <a href="${url}" target="_blank">
                                <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                            </a>
                        </div>`,
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            );
        };

        const productResult = await api.getProducts({ productName: '', typeId: '', status: true, keyword: '' });
        const products = productResult.data?.products || [];

        const clients = await generalStore.dispatch('loadBusinessClients');

        const priceTypes = ref<PriceTypeResource[]>([]);
        const customerTypes = ref<CustomerType[]>([]);
        const currencies = ref<IdText[]>([]);
        const discountTypes = computed(() => [...currencies.value, { id: '', text: '%' }]);
        const subCouponStatusesOptions = enumSubCouponStatusToArray(SubCouponStatus);

        const today = new Date();

        async function updateOptions() {
            if (!couponDateRange.fromDate || !couponDateRange.toDate || coupon.value.products.length == 0) {
                return;
            }

            const response = await api.getCouponOptions(
                coupon.value.products.map(x => x.id),
                convertDate(couponDateRange.fromDate),
                convertDate(couponDateRange.toDate),
                coupon.value.priceTypes.map(x => x.id),
                coupon.value.customerTypes.map(x => x.id)
            );

            if (response.error) {
                swal.fire({
                    icon: 'error',
                    text: 'Error'
                });
                return;
            }
            if (response.data) {
                priceTypes.value = response.data.priceTypes ?? [];
                customerTypes.value = response.data.customerTypes ?? [];
                currencies.value = response.data.currencies ?? [];
                coupon.value.currencyId = response.data.currencies.length > 0 ? response.data.currencies[0].id : '';
            }
        }

        await updateOptions();

        async function updateCoupon() {
            if (coupon.value.discountType == CouponDiscountType.Discount && coupon.value.discountAmount == 0) {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: t('coupons.warning.discount-zero'),
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.save'),
                    cancelButtonText: t('button.cancel')
                });

                if (swalResult.isDismissed) {
                    return;
                }
            }
            swal.showLoading();
            const couponToApiRequest = _.cloneDeep(coupon.value);
            if (couponDateRange.fromDate) {
                couponToApiRequest.validDateFrom = convertDate(couponDateRange.fromDate);
            }
            if (couponDateRange.toDate) {
                couponToApiRequest.validDateTo = convertDate(couponDateRange.toDate);
            }
            if (coupon.value.discountType == CouponDiscountType.Discount) {
                if (discountIsAmount.value) {
                    couponToApiRequest.discountPercent = null;
                } else {
                    couponToApiRequest.discountAmount = null;
                }
                couponToApiRequest.ticketFixedUnitPrice = null;
            } else if (coupon.value.discountType == CouponDiscountType.TicketFixedUnitPrice) {
                couponToApiRequest.discountPercent = null;
                couponToApiRequest.discountAmount = null;
            } else if (coupon.value.discountType == CouponDiscountType.NoDiscount) {
                couponToApiRequest.discountPercent = null;
                couponToApiRequest.discountAmount = null;
                couponToApiRequest.ticketFixedUnitPrice = null;
            }
            const saveCouponPromise = api.saveCoupon(couponToApiRequest);
            await Q.delay(400);
            const saveCouponResponse = await saveCouponPromise;
            if (!saveCouponResponse.data || saveCouponResponse.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: saveCouponResponse.errorMessage });
                return;
            }
            await swal.fire({ icon: 'success', text: t('coupons.created') });
            router.go(-1);
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                const invalidFields = event.target.querySelectorAll(':invalid');
                invalidFields.forEach((field: any) => {
                    console.log(`Поле: ${field.name || field.id}, Ошибка: ${field.validationMessage}`);
                });
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
            } else {
                updateCoupon();
            }
        };

        function GetCurrencySymbol(currencyId: string) {
            return generalStore.getters.getCurrencySymbol(currencyId);
        }

        function close() {
            router.go(-1);
        }

        function checkIsNumber(evt: Event) {
            const target = evt.target as HTMLTextAreaElement;
            const res = parseInt(target.value);
            if (isNaN(res)) {
                coupon.value.discountAmount = '';
            } else {
                if (res < 0) {
                    coupon.value.discountAmount = target.value.replace(/\D+/g, '');
                    return;
                }
                coupon.value.discountAmount = res.toString();
            }
        }

        async function onActivate() {
            if (!coupon.value.active) {
                coupon.value.active = true;
                return;
            }
            const swalAction = await swal.fire({
                icon: 'info',
                text: t('coupon.deactivate-message'),
                customClass: {
                    confirmButton: 'green-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeactivate'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalAction.isConfirmed) {
                coupon.value.active = true;
                return;
            }
            coupon.value.active = false;
        }

        async function selectAllClubs() {
            if (!clients[0]) {
                return;
            }

            coupon.value.clubs = clients;
            await updateOptions();
        }

        async function selectAllCustomerTypes() {
            if (!customerTypes.value[0]) {
                return;
            }

            coupon.value.customerTypes = customerTypes.value;
            await updateOptions();
        }

        async function selectAllProducts() {
            if (!products[0]) {
                return;
            }

            coupon.value.products = products;
            await updateOptions();
        }

        async function selectAllPriceTypes() {
            if (!priceTypes.value[0]) {
                return;
            }

            coupon.value.priceTypes = priceTypes.value;
            await updateOptions();
        }

        async function cleanCustomerType() {
            coupon.value.customerTypes = [];
            coupon.value.currencyId = '';
            customerTypes.value = [];
            currencies.value = [];
            await updateOptions();
        }

        async function cleanPriceType() {
            coupon.value.customerTypes = [];
            coupon.value.currencyId = '';
            customerTypes.value = [];
            currencies.value = [];
            coupon.value.priceTypes = [];
            priceTypes.value = [];
            await updateOptions();
        }

        async function cleanProduct() {
            coupon.value.customerTypes = [];
            coupon.value.currencyId = '';
            customerTypes.value = [];
            currencies.value = [];
            coupon.value.priceTypes = [];
            priceTypes.value = [];
            coupon.value.products = [];
            await updateOptions();
        }

        async function changeSubCouponStatus(subCouponCode: string, status: SubCouponStatus) {
            swal.showLoading();

            const response = await api.changeSubCouponStatus(couponId.value!, status, subCouponCode);

            if (response.error || response.data === undefined) {
                swal.fire({
                    icon: 'error',
                    text: response.error?.message || 'No data'
                });
                return;
            } else if (response.data)
                swal.fire({
                    icon: 'success',
                    text: 'Status changed successfully'
                });

            await filterSubCoupons();
            swal.close();
        }

        function onClickCouponType(couponType: CouponType) {
            if (couponType == CouponType.BusinessClientAgreement) {
                return;
            }
            if (coupon.value.discountType == CouponDiscountType.NoDiscount) {
                coupon.value.discountType = CouponDiscountType.Discount;
            }
            coupon.value.whoFinances = CouponWhoFinances.NoFinance;
            coupon.value.club = new Club();
        }

        function onClickCouponDiscountType(couponDiscountType: CouponDiscountType) {
            if (couponDiscountType == CouponDiscountType.NoDiscount) {
                coupon.value.whoFinances = CouponWhoFinances.EntireOrder;
            }
            if (couponDiscountType == CouponDiscountType.TicketFixedUnitPrice && coupon.value.whoFinances == CouponWhoFinances.Discount) {
                coupon.value.whoFinances = CouponWhoFinances.EntireOrder;
            }
        }

        async function deleteCoupon(){
            if(coupon.value.id){
                const swalAction = await swal.fire({
                    icon: 'question',
                    text: t('delete.alert'),
                    customClass: {
                        confirmButton: 'delete-button popup-bookit-button my-1 px-5',
                        cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('button.yes'),
                    cancelButtonText: t('button.no')
                });

                if (swalAction.isConfirmed) {
                    swal.showLoading();
                    const response = await api.deleteCoupon(coupon.value.id);

                    if (response.errorMessage) {
                        swal.fire({
                            icon: 'error',
                            text: response.errorMessage
                        });
                        return;
                    } else if (response.data){
                        swal.fire({
                            icon: 'success',
                            showConfirmButton: false,
                            showCancelButton: false
                        });
                    }
                    
                    router.go(-1);
                }
            }
        }

        return {
            cleanCustomerType,
            cleanPriceType,
            cleanProduct,
            selectAllCustomerTypes,
            selectAllProducts,
            selectAllPriceTypes,
            selectAllClubs,
            limitationTypes,
            coupon,
            couponDateRange,
            products,
            clients,
            discountTypes,
            currencies,
            priceTypes,
            customerTypes,
            updateCoupon,
            submitForm,
            close,
            updateOptions,
            today,
            isEdit,
            CouponLimitationType,
            couponPassed,
            GetCurrencySymbol,
            checkIsNumber,
            onActivate,
            subCouponsLeft,
            subCouponsReserved,
            subCouponsUsed,
            showCodes,
            subCouponStatusLabels,
            filter,
            filterSubCoupons,
            showAll,
            subCoupons,
            subCouponTableContainer,
            handleScroll,
            subCouponStatusesOptions,
            exportSubCoupons,
            changeSubCouponStatus,
            SubCouponStatus,
            CouponType,
            CouponDiscountType,
            CouponWhoFinances,
            onClickCouponType,
            onClickCouponDiscountType,
            discountCurrencyId,
            discountIsAmount,
            deleteCoupon,
            businessClientTypesTranslations,
            isClubDisabled
        };
    }
});
